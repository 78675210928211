<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- Personal Details tab -->
    <b-tab active>
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Personal Details </span>
      </template>

      <account-setting-general
        v-if="options.general"
        :general-data="options.general"
      />
    </b-tab>
    <!--/ Personal tab -->

    <!-- Other Details tab -->
    <b-tab active>
      <template #title>
        <feather-icon icon="AlertCircleIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Other Details </span>
      </template>

      <account-setting-general
        v-if="options.general"
        :general-data="options.general"
      />
    </b-tab>
    <!--/ Other tab -->

    <!-- Security Tab -->
    <b-tab>
      <template #title>
        <feather-icon icon="KeyIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Security (Password)</span>
      </template>
      <account-security-settings />
    </b-tab>
    <!-- Security Tab -->


  </b-tabs>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import ProfileServices from "@/apiServices/ProfileServices";
import storageService from "@/apiServices/storageService";

export default {
  components: {
    BTabs,
    BTab
  },
  data() {
    return {
      options: {},
      profileData: null,
    };
  },
  beforeCreate() {

    // const profileData = storageService.UserData.getUserProfile();
    // let uid = JSON.parse(profileData);
    // const sendData = { id: uid.user_id };

    // ProfileServices.getUserProfileData(sendData)
    //   .then((response) => {
    //     console.log("GET USER DATA: ", response);
    //     this.profileData = response.data.data;
    //     this.options = response.data.data;
    //   })
    //   .catch((error) => {
    //     this.errorMessage = error.message;
    //     console.error("There was an error!", error);
    //   });

  },
  
};
</script>
