import axiosInstance from "./Api";

export default {

  async getUserProfileData(payload) {
    console.log(payload);
    let response = await axiosInstance().post("/profile", payload);
    console.log(response);
    return response;
  },

  async updatePassword(payload) {
    console.log(payload);
    let response = await axiosInstance().post("/profile/updatePassword", payload);
    console.log(response);
    return response;
  },

};
